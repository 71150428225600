.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #00000030;

  .modalContent {
    position: relative;
    padding: 20px 30px;
    background: #fff;
    border: 1px solid #eaedf3;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 4%);

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
      width: 20px;
      height: 20px;
      background-image: url("/assets/close-new.svg");
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;

      &:hover {
        filter: brightness(100%);
      }
    }
  }
}
