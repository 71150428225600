@import "/src/styles/vars";

.gridItemContainer {
  display: block;
  color: inherit;

  &:hover {
    .title {
      text-decoration: underline;
    }

    .image {
      transform: scale(1.1);
    }
  }

  .gridItem {
    min-height: 372px;
    padding-bottom: 5px;
    border: 1px solid $borderColor;

    .textContainer {
      padding: 15px 20px;

      .title {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .date {
        display: grid;
        grid-template: 1fr 1fr /18px 1fr;
        gap: 10px 5px;
        align-items: center;
        color: $textSecondary;
        font-size: 0.87rem;
        line-height: 1.375rem;

        & > p {
          margin-bottom: -4px;
        }
      }

      .iconReturnFlight {
        width: 18px;
        height: 18px;
        background-image: url("/assets/return.svg");
        background-size: contain;
        opacity: 0.5;
      }

      .iconDepartFlight {
        width: 18px;
        height: 18px;
        background-image: url("/assets/depart.svg");
        background-size: contain;
        opacity: 0.5;
      }
    }

    .imageContainer {
      position: relative;
      height: 200px;
      overflow: hidden;
      border-radius: 4px;

      .chip {
        position: absolute;
        bottom: 10px;
        left: 10px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        padding: 0 10px;
        background-color: #1763ab;
        border: 1px solid #0f467a;
        border-radius: 20px;

        p {
          color: #fff;
          font-weight: 500;
        }
      }

      .chipMultiFlightType {
        background-color: #1c7cd6;
        border: 1px solid #1460a8;
      }

      .chipRoundFlightType {
        background-color: #26a2d6;
        border: 1px solid #1b749b;
      }

      .chipSingleFlightType {
        background-color: #47bee3;
        border: 1px solid #3b9cba;
      }

      .image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        transition: all 0.4s;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 15px 0;
    border-top: 1px solid $borderColor;

    .btnMenu {
      display: flex;
      gap: 10px;
      padding: 5px;

      & > * {
        padding: 5px;
        color: $textSecondary;

        &:hover {
          color: $textBase;
        }
      }
    }
  }
}

.clockIcon {
  @include iconSize;

  color: #9ea0a5;
}

.trashIcon {
  @include iconSize;
}

.editIcon {
  @include iconSize;
}
