.loadBar {
  position: absolute;
  top: 7vh;
  z-index: 101;
  width: 100%;
  height: 6px;
  overflow: hidden;
  background-color: #3781d8;
}

.loadBlock {
  position: absolute;
  top: 7vh;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 93%;
  background: #ffffff80;
}

.bar {
  position: absolute;
  left: 50%;
  display: inline;
  width: 0;
  height: 100%;
  text-align: center;
  content: "";
}

.bar:nth-child(1) {
  background-color: #47bee3;
  animation: loading 3s linear infinite;
}

.bar:nth-child(2) {
  background-color: #3ea2d6;
  animation: loading 3s linear 1s infinite;
}

.bar:nth-child(3) {
  background-color: #3781d8;
  animation: loading 3s linear 2s infinite;
}
@keyframes loading {
  0% {
    left: 50%;
    z-index: 100;
    width: 0;
  }

  33.3333% {
    left: 0;
    z-index: 10;
    width: 100%;
  }

  100% {
    left: 0;
    width: 100%;
  }
}
