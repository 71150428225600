@import "/src/styles/vars";

.optionInfoContainer {
  border-bottom: 1px solid #dadce0;

  span {
    padding: 0 5px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 30px;
    border-bottom: 1px solid #dadce0;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 10px;
      padding: 10px;
    }

    h3 {
      display: flex;
      align-items: center;

      .iconDepartFlight,
      .iconReturnFlight {
        width: 25px;
        height: 25px;
        margin-right: 25px;
        background-image: url("/assets/depart.svg");
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.5;
      }

      .iconReturnFlight {
        background-image: url("/assets/return.svg");
      }
    }

    .optionContainer {
      display: flex;
      gap: 30px;

      .optionTime,
      .optionStops {
        display: flex;
        flex-direction: column;
        align-items: center;

        :first-child {
          font-weight: 500;
          font-size: 26px;
        }

        :last-child {
          font-weight: 500;
          font-size: 15px;
        }
      }
    }
  }
}
