@import "/src/styles/vars";

.container {
  position: relative;
  display: grid;
  grid-template: 1fr /40px 1fr;
  gap: 15px;

  & > p {
    color: inherit;
    font-size: 1.2rem;
  }

  svg,
  span {
    width: 25px;
    height: 25px;
  }
}
