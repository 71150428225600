.container {
  display: grid;
  gap: 30px;
}

.title {
  font-weight: 800;
  font-size: 22px;
  font-family: Nunito, sans-serif;
  text-align: center;
  transform: scaleY(1.1);
}

.input {
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: 0.875rem;
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  outline: none;
  transition: all 0.2s;
}

.buttonGroup {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 200px;
}
