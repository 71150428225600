.segmentWrapper {
  padding: 25px 30px;

  .segmentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;

    .info {
      margin-top: 15px;
      margin-right: -7px;
      margin-left: 95px;
      padding-bottom: 35px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      border-bottom: 1px solid #dadce0;
    }

    .segmentContent {
      position: relative;
      display: flex;
      align-items: center;

      .image {
        width: 35px;
        height: 35px;
        margin-right: 60px;
        background-size: contain;
        border-radius: 50%;
      }

      .divider {
        position: absolute;
        left: 68px;
        width: 12px;
        height: 80px;
        background-repeat: no-repeat;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90px;
      }

      .title {
        .offset {
          margin-top: -10px;
          margin-left: 1px;
          font-size: 12px;
        }

        margin: -10px 0;
        color: #3c4043;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .time {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      @media screen and (max-width: 768px) {
        padding: 0 0 30px;

        .divider {
          display: none;
        }

        .image {
          display: none;
        }

        .title {
          display: inline-block;
          margin: 5px 0;
          font-size: 14px;
        }

        .info {
          margin-top: 15px;
          margin-right: 0;
          margin-left: 0;
          padding-bottom: 30px;
        }

        .content {
          height: unset;
        }
      }
    }
  }
}
