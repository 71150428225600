@import "normalize.css";
@import "_vars.scss";
@import "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap&subset=cyrillic-ext";
@import "https://fonts.googleapis.com/css?family=Nunito:700,800&display=swap";

html,
body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  color: $textMain;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.backImg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

@keyframes backslide {
  0% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  83% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.backImg.hidden {
  animation-name: backslide;
  animation-duration: 15s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

p {
  color: $textSecondary;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

input,
textarea {
  color: $textMain;
  font-size: 0.875rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.loader-back {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: #fbfbfd;
}

.animatedBg {
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 2s;
}

.loader {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 300px);
  z-index: 1000;
  width: 600px;
  height: 200px;
  background-image: url("/assets/logo-horizontal.png");
  background-size: contain;
  animation: animate 1s ease-in-out infinite alternate;

  @keyframes animate {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.2);
    }
  }

  @media screen and (max-width: 768px) {
    left: calc(50% - 37.5vw);
    max-width: 75vw;
    max-height: 25vw;
  }
}
