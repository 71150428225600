@import "/src/styles/vars";

@mixin addCloseImage {
  width: 100%;
  height: 100%;
  background-image: url("/assets/plus.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-size: contain;
}

.dashboard {
  z-index: 1;
}

.gridViewContainer {
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex-basis: calc(33.3% - 30px);
    margin: 15px;

    @media screen and (max-width: 1350px) {
      flex-basis: calc(33.3% - 30px);
      max-width: 340px;
    }

    @media screen and (max-width: 1100px) {
      flex-basis: calc(50% - 30px);
    }

    @media screen and (max-width: 760px) {
      flex-basis: calc(100% - 30px);
      width: 247px;
    }
  }

  .addItem {
    min-height: 372px;
    padding: 0 15px 20px;
    background: 0 0;
    border: 1px solid #eaedf3;
    outline: none;
    cursor: pointer;

    &:disabled {
      filter: grayscale(100%);
    }

    .addImageContainer {
      width: 100%;
      height: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      overflow: hidden;
      border-radius: 4px;
      transition: all 0.4s;

      &:hover {
        transform: scale(1.1);
      }

      .addImage {
        @include addCloseImage;
      }

      .closeImage {
        @include addCloseImage;

        transform: rotate(45deg);
        filter: hue-rotate(215deg);
      }
    }
  }
}

.mainContainer {
  @include mainContainer;
}

.warning {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  max-width: 100%;

  svg {
    width: 25px;
    height: 25px;
  }

  a :first-child {
    border-radius: 5px 0 0 5px;

    span > svg {
      margin-right: 10px;
    }
  }

  :last-child {
    border-radius: 0 5px 5px 0;
  }
}

.errorTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-weight: 800;
  font-size: 22px;
  font-family: nunito, sans-serif;
  text-align: center;
  transform: scaleY(1.1);
}
