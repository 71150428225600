.wrapper {
  padding: 25px 30px;

  form {
    display: flex;
    flex-direction: column;
    padding: 15px 0;

    button {
      margin-top: 30px;
    }
  }

  .title {
    font-weight: 800;
    font-size: 22px;
    font-family: nunito, sans-serif;
    text-align: center;
    transform: scaleY(1.1);
  }

  .subtitle {
    color: #3e3f42;
    font-weight: 800;
    font-size: 20px;
    font-family: nunito, sans-serif;
    text-align: center;
    transform: scaleY(1.1);
  }

  .groupButton {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;

      span {
        justify-content: center;
      }
    }
  }
}

.label {
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 18px;
  font-family: Nunito, sans-serif;
  text-align: center;
  text-transform: capitalize;
  transform: scale(1, 1.1);
}

.desc {
  font-weight: 800;
  font-size: 18px;
  font-family: Nunito, sans-serif;
  text-align: center;
  transform: scale(1, 1.1);
}

input,
select {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  margin-bottom: 10px;
  padding: 0 18px;
  color: black;
  font-weight: 400;
  font-size: 0.875rem;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid black;
  border-radius: 4px;
  transition: all 0.1s ease-in-out 0s;
  user-select: none;
}

label {
  color: #9ea0a5;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-transform: uppercase;
}

select {
  margin-bottom: 10px;
}
