@import "src/styles/vars";

@mixin widgetWrapper {
  min-width: 350px;
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 4%);
}

.splitWrapper {
  padding: 40px;

  @media screen and (max-width: 800px) {
    padding: 20px 0;
  }
}

.splitContent {
  display: flex;
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  .subNavWidget {
    height: 100%;

    @include widgetWrapper;

    .userImage {
      position: relative;
      padding-top: 300px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      button {
        display: none;
      }

      &:hover button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: unset;

        & :first-child {
          position: relative;
          font-size: 18px;
          transform: unset;
        }

        & :last-child {
          display: flex;
          flex-grow: unset;
          height: 100%;
        }
      }

      .userBtn {
        color: #fff;
        background: rgb(62 63 66 / 60%);
        border: 1px solid rgb(62 63 66 / 80%);
        box-shadow: 0 1px 1px 0 rgb(19 31 21 / 10%),
          inset 0 2px 0 0 hsl(0deg 0% 100% / 6%);
        transition: all 0.4s;

        .cameraBgIcon {
          position: absolute;
          width: 300px;
          height: 300px;
          color: rgb(255 255 255 / 10%);
        }

        &:not(:disabled) {
          cursor: pointer;
        }

        &:hover {
          background: rgb(62 63 66 / 70%);
          border: none;
          box-shadow: 0 1px 1px 0 rgb(19 31 21 / 18%),
            inset 0 2px 0 0 hsl(0deg 0% 100% / 6%);
        }
      }
    }
  }

  .userMeta {
    padding: 25px 30px;

    h2 {
      font-weight: 500;
      font-size: 1.375rem;
      line-height: 2rem;
    }

    p > * {
      display: block;
    }
  }

  .formWidgetContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .formWidget {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include widgetWrapper;

    & > div {
      padding: 25px 30px;

      &:not(:last-child) {
        border-bottom: 1px solid $borderColor;
      }
    }

    .dropZone {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      color: #bdbdbd;
      background-color: #fafafa;
      border: 2px dashed #eee;
      border-radius: 2px;
      outline: none;
      transition: border 0.24s ease-in-out;
    }

    .editUserInfo {
      display: grid;
      grid-template: 1fr / 1fr 1fr;
      gap: 0 15px;

      & > :last-child {
        grid-column: 1 / 3;
        padding-top: 20px;
      }

      label {
        color: #9ea0a5;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
        text-transform: uppercase;
      }

      input {
        display: block;
        width: 100%;
        padding: 10px 15px;
        font-size: 0.875rem;
        background: #fff;
        border: 1px solid #eaedf3;
        border-radius: 4px;
        outline: none;
        transition: all 0.2s;

        &:focus {
          border: 1px solid $blue;
        }
      }
    }
  }
}
