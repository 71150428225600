@import "/src/styles/vars";

.addForm {
  padding: 0 20px 20px;

  form {
    display: flex;
    flex-direction: column;
  }

  .label {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 18px;
    font-family: Nunito, sans-serif;
    text-align: center;
    text-transform: capitalize;
    transform: scale(1, 1.1);
  }

  .desc {
    font-weight: 800;
    font-size: 18px;
    font-family: Nunito, sans-serif;
    text-align: center;
    transform: scale(1, 1.1);
  }

  .groupButton {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  input,
  select {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    margin-bottom: 10px;
    padding: 0 18px;
    color: $textMain;
    font-weight: 400;
    font-size: 0.875rem;
    white-space: nowrap;
    vertical-align: middle;
    background: #fff;
    border: 1px solid $borderColorDarker;
    border-radius: 4px;
    transition: all 0.1s ease-in-out 0s;
    user-select: none;
  }

  label {
    color: #9ea0a5;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-transform: uppercase;
  }

  select {
    margin-bottom: 10px;
  }
}
