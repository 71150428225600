@import "/src//styles/vars";

.wrapper {
  margin-bottom: 10px;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 50px 0;
}

.widgetWrapper {
  position: absolute;
  z-index: 2;
  display: block;
  max-width: 90vw;
  margin: 0 auto 35px;
  background: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 8%);
}

.authLogo {
  width: 370px;
  max-width: 80vw;
  height: 70px;
  margin: 30px auto 10px;
  background-image: url("/assets/cut_logo.png");
  background-repeat: no-repeat;
  background-position-x: 200%;
  background-size: contain;
}

.widgetContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  max-width: 90vw;
  max-height: 330px;
  padding: 5px 30px 25px;
  font-family: nunito, sans-serif;

  .headline {
    width: unset;
    margin-top: 0;
    font-weight: 700;
    font-size: 2.6vh;
    text-align: center;
    transform: scaleY(1.07);
  }

  .formInputGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 250px;

    &__subtitle {
      color: #3e3f42;
      font-size: 16px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: inherit;
      height: auto;
      min-height: 40px;
      padding: 8px 16px;
      font-weight: 500;
      line-height: normal;
      direction: ltr;
      text-align: center;
      border-radius: 2px;

      svg {
        margin-right: 10px;
      }

      span {
        font-weight: 600;
        font-family: nunito, sans-serif;
      }
    }

    .inputContainer {
      width: 100%;
      max-width: 360px;

      label {
        color: #9ea0a5;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
        text-transform: uppercase;
      }

      input {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        padding: 10px 15px;
        font-size: 0.875rem;
        background: #fff;
        border: 1px solid #eaedf3;
        border-radius: 4px;
        outline: none;
        transition: all 0.2s;

        &:focus {
          border: 1px solid $blue;
        }

        &:invalid {
          border: 1px solid $red;
        }
      }
    }
  }

  .footer {
    display: grid;
    gap: 5px;
    max-width: 330px;
    color: #9ea0a5;
    text-align: center;

    .text {
      font-size: 14px;
    }
  }

  .successEmailInfo {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 270px;
    font-weight: 700;
    font-size: 0.85rem;
    font-family: nunito, sans-serif;
    line-height: 2rem;
    text-align: center;
    transform: scaleY(1.07);

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 24px;
      height: 24px;
      margin-left: 5px;
    }

    .magicLink {
      max-width: 390px;
    }

    .spam {
      max-width: 320px;
    }
  }
}

@media screen and (min-height: 700px) {
  .pageWrapper {
    justify-content: center;
  }
}
