@import "/src/styles/vars";

.dropdownMenu {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 102;
  min-width: 150px;
  margin-top: 4px;
  list-style: none;
  background: #fff;
  border: 1px solid $borderColorDarker;
  border-top: none;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 8%);

  .dropdownItem {
    display: block;
    padding: 12px 16px;
    color: $textMain;
    font-size: 0.875rem;
    text-align: left;
    text-transform: capitalize;
    border-top: 1px solid $borderColorDarker;

    &:hover {
      background-color: #eee;
    }
  }
}
