.buttonContainer {
  height: 38px;
  padding: 0 18px;
  color: #000;
  font-size: 0.875rem;
  background-color: #fff;
  border: 1px solid #d8dce6;
  border-radius: 5px;
  cursor: pointer;
  transition: background-position 0.4s ease-in-out;
  user-select: none;

  &:hover {
    background-position: 100% 0;
    border-color: #adadad;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.primary {
  color: #fff;
  background: linear-gradient(
    90deg,
    rgb(71 190 227 / 100%) 0%,
    rgb(62 162 214 / 100%) 20%,
    rgb(53 124 214 / 100%) 40%,
    rgb(53 124 214 / 100%) 60%,
    rgb(62 162 214 / 100%) 80%,
    rgb(71 190 227 / 100%) 100%
  );
  background-size: 300% 100%;
  border: rgb(71 190 227 / 100%);

  &:hover {
    background-position: 100% 0;
    box-shadow: 0 1px 1px 0 rgb(63 63 100 / 18%),
      inset 0 2px 0 0 rgb(39 31 58 / 6%);
  }
}
