.headerContainer {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7vh;
  background: #fff;
  border-bottom: 1px solid #eaedf3;

  .logoWrapper {
    display: flex;
    align-items: center;
    padding: 0 30px;

    @media screen and (max-width: 800px) {
      padding: 0;
    }

    .logo {
      display: flex;
      align-items: center;

      a {
        width: 240px;
        height: 65px;
        background-image: url("/assets/logo-horizontal.png");
        background-size: contain;
      }
    }
  }

  .menu {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0 30px;

    @media screen and (max-width: 800px) {
      padding: 0;
    }

    .homeIcon {
      width: 20px;
      height: 20px;
      background-image: url("/assets/home-new.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .avatarIcon {
      width: 40px;
      height: 40px;
      object-fit: contain;
      border-radius: 50%;
      cursor: pointer;
    }

    .dropdownArrowIcon {
      position: relative;
      width: 20px;
      height: 20px;
      background-image: url("/assets/up-down-arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
}
