.commentsContent {
  width: 269px;
  max-width: 270px;
  height: calc(100% - 15px);

  .title {
    position: fixed;
    z-index: 1;
    display: block;
    width: 269px;
    width: 100%;
    padding: 10px 30px;
    color: #9ea0a5;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5;
    text-transform: uppercase;
    background: #fff;
  }

  .commentArea {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 269px;
    max-width: 270px;
    max-height: 100px;
    padding: 10px 20px;
    background: #fff;

    p {
      padding-bottom: 20px;
      color: #000;
      font-size: 16px;
      white-space: normal;
      word-break: break-word;
    }

    .avatar {
      position: relative;
      flex-shrink: 0;
      width: 38px;
      height: 38px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
    }

    textarea {
      flex-grow: 1;
      min-height: 75px;
      margin-left: 20px;
      padding-top: 8px;
      padding-left: 8px;
      line-height: 1.4rem;
      border: unset;
      outline: none;
      resize: none;
    }
  }

  .messageList {
    height: 93vh;
    padding: 30px 0 95px;
    overflow: auto;

    @media screen and (max-width: 1200px) {
      height: 90vh;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .messageContainer {
      display: flex;
      align-items: center;
      min-height: 95px;
      padding: 10px 20px;

      .messageContent {
        display: flex;
        align-items: center;
        max-width: 100%;

        .avatar {
          position: relative;
          flex-shrink: 0;
          width: 38px;
          height: 38px;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .email {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            color: #3e3f42;
            font-size: 0.875rem;
            line-height: 1.375rem;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .text {
            color: #000;
            font-size: 16px;
            white-space: normal;
            word-break: break-word;
          }

          .date {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
