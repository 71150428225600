@import "/src/styles/vars";

.success {
  color: rgb(30 70 32);
  background-color: rgb(237 247 237);

  svg {
    color: #4caf50;
  }
}

.pending {
  color: rgb(102 60 0);
  background-color: rgb(255 244 229);

  svg {
    color: rgb(255 152 0);
  }
}

.headlineMain {
  .optionMenu {
    display: grid;
    grid-template: 1fr / 200px 5% 1fr;
    align-items: center;

    svg {
      width: 25px;
      height: 25px;
      color: #3e3f42;
      cursor: pointer;
    }

    button {
      margin-left: 20px;
      color: #fff;
      font-size: 1.7rem;
      background-color: $green;
      border: none;

      &:hover {
        background: #36a045;
      }
    }

    a {
      display: block;
    }

    :last-child {
      justify-self: end;
    }
  }
}

.flightWrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;

  .flightContainer {
    display: flex;
    flex-grow: 1;
    max-width: 1640px;
    height: 100%;
    margin: auto;
    overflow: hidden;

    .mobileNavigation {
      position: fixed;
      bottom: 0;
      display: none;
      width: 100vw;
      background: #fff;
      border-top: 1px solid #eaecf3;
      box-shadow: 0 -2px 3px 0 rgb(0 0 0 / 4%);

      @media screen and (max-width: 1200px) {
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
      }

      span {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 0.875rem;
        cursor: pointer;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .scrollWrapper {
      width: 100%;
      max-width: 1100px;
      height: 93vh;
      margin-left: auto;
      overflow-y: auto;
      transition: all 1s;

      &::-webkit-scrollbar {
        display: none;
      }

      & > * {
        margin: 20px;
      }

      @media screen and (max-width: 1200px) {
        min-width: 100%;
      }

      @media screen and (min-width: 1200px) {
        transform: translateX(0);
      }

      .flightHeader {
        position: relative;
        display: block;
        background: #fff;
        border: 1px solid #eaedf3;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 4%);

        .bgImage {
          position: relative;
          height: 308px;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: cover;
          border-radius: 4px 4px 0 0;

          &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
                -180deg,
                rgb(0 0 0 / 35%) 9%,
                transparent 40%
              ),
              linear-gradient(-180deg, transparent 60%, rgb(0 0 0 / 35%) 99%);
            border-radius: 4px 4px 0 0;
            content: "";
          }

          .headline {
            position: absolute;
            bottom: 15px;
            left: 180px;
            z-index: 100;
            color: #fff;
            font-weight: 500;
            font-size: 1.375rem;
            line-height: 2rem;
          }
        }

        .backBtn {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: #3c4043;
          font-size: 1.125rem;
          background: #fff;
          border-radius: 50%;
          cursor: pointer;
        }

        .headerMenu {
          display: grid;
          grid-template: 100% / 1fr 1fr;
          @media screen and (max-width: 768px) {
            grid-template: 70% 30%/1fr;
          }

          .tabGroup {
            display: flex;
            flex: 3 1;
            flex-shrink: 0;
            justify-content: space-between;
            width: 100%;
            padding: 0 15px;
            overflow-x: hidden;
          }

          .btnGroup {
            display: flex;
            align-items: center;
            justify-content: end;
            padding: 0 20px;

            @media screen and (max-width: 768px) {
              width: 100%;
              padding: 0;

              button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              }
            }

            svg {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          }
        }
      }

      .lowestPricesContainer {
        border: 1px solid #eaedf3;
        border-bottom: none;
        border-radius: 4px;

        .lowestPricesItem {
          display: grid;
          grid-template: 1fr / 85% 15%;
          align-items: center;
          padding: 10px 20px;
          border-bottom: 1px solid #eaedf3;
          border-radius: 4px;

          &:hover {
            background: $bg;
          }

          .lowestPricesTitle {
            color: $blue;
            font-weight: 500;
          }

          .lowestPricesButton {
            justify-self: center;

            svg {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }

          button {
            color: #fff;
            font-weight: 600;
            font-size: 1rem;
            background-color: #38b249;
            border: none;
          }
        }
      }

      .flightMain {
        display: block;
        background: #fff;
        border: 1px solid #eaedf3;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 4%);
      }
    }

    .optionLink {
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }

    .commentsContainer {
      flex-grow: 0;
      width: 270px;
      max-width: 100%;
      height: 100%;
      background: #fff;
      border-right: none;
      border-left: 1px solid #eaedf3;
      transform: translateX(0);

      @media screen and (max-width: 1200px) {
        transform: translateX(150%);
        transition: all 0.4s;
      }

      @media screen and (min-width: 1200px) {
        transform: translateX(0) !important;
      }
    }
  }
}

.tabItem {
  display: flex;
  align-items: center;
  height: 70px;
  margin: 0 15px;
  color: #6b6c6f;
  border-bottom: 3px solid transparent;
  cursor: pointer;

  &:hover {
    color: #3e3f42;
    border-bottom-color: rgb(22 101 216 / 30%);
  }

  .amountTabItem {
    margin-left: 5px;
    color: $textSecondary;
  }
}

.activeTab {
  color: #3e3f42;
  font-weight: 500;
  border-bottom-color: $blue;
}

.hideFlight {
  transform: translateX(-150%);
}

.showMessage {
  transform: translateX(-100vw) !important;
}

.priceSearchBlockWrapper {
  position: relative;
  padding: 30px 20px;
  border-radius: 4px;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    width: 20px;
    height: 20px;
    background-image: url("/assets/close-new.svg");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    &:hover {
      filter: brightness(100%);
    }
  }
}

.warning {
  display: flex;
  align-items: center;
  padding: 20px 20px 10px;
}

.inDevelopingWrapper {
  display: block;
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 4%);

  div {
    padding: 30px;
  }
}

.modalBtnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;

  &::before {
    position: absolute;
    top: 73%;
    left: 0;
    z-index: 1;
    width: 100%;
    border-bottom: solid 1px $borderColor;
    content: "";
  }

  & :last-child {
    color: #fff;
    background-color: $green;
  }
}
