$mediaMobile: "screen and (max-width: 768px)";
$mediaTablet: "screen and (max-width: 1024px)";
$mediaDesktopSmall: "screen and (max-width: 1366px)";
$fontFamily: "Roboto", sans-serif;

$bg: #fbfbfd;
$bgDark: #252529;
$borderColor: #eaedf3;
$borderColorDarker: #d8dce6;
$borderDefault: 1px solid $borderColor;
$borderColorDark: #2e2e33;

$textMain: #3e3f42;
$textBase: #6b6c6f;
$textSecondary: #9ea0a5;
$textLight: #cecfd2;

$green: #38b249;
$blue: #1665d8;
$orange: #f6ab2f;
$violet: #6977ff;
$red: #e6492d;
$yellow: #facf55;
$success: $green;
$primary: $blue;
$warning: $orange;
$error: $red;

$colorPairs: (green, #38b249), (blue, #1665d8), (orange, #f6ab2f),
  (violet, #6977ff), (yellow, #facf55), (red, #e6492d);

@mixin textOverflow {
  overflow: hidden;
  white-space: nowrap;
}

@mixin backgroundCover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin mainContainer {
  max-width: 1200px;
  margin: auto;
}

@mixin iconSize {
  min-width: 20px;
  min-height: 20px;
}

$boxShadow: 0 1px 3px 0 rgb(0 0 0 / 4%);
